.container-mcq {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 100vh;
  overflow-y: auto;
}

.mcq-content {
  max-width: 800px;
  margin: 0 auto;
}

.question-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-text {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.option input[type="radio"] {
  margin-right: 0.5rem;
}

.submit-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Dark mode styles */
.dark-mode .container-mcq {
  background-color: #1a1a1a;
  color: #fff;
}

.dark-mode .question-card {
  background-color: #2c2c2c;
  color: #fff;
}

.dark-mode .submit-button {
  background-color: #0056b3;
}

.dark-mode .submit-button:hover {
  background-color: #003d82;
}

.dark-mode .submit-button:disabled {
  background-color: #444;
}

/* Improved document selection styles */
.document-selection {
  margin: 2rem auto;
  text-align: center;
  max-width: 600px;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.selectors-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dark-mode .selectors-wrapper {
  background-color: #2c2c2c;
}

.selector-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.selector-group label {
  font-weight: 500;
  color: #333;
  font-size: 0.9rem;
}

.dark-mode .selector-group label {
  color: #ccc;
}

.document-selection {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
}

.document-selection h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.dark-mode .document-selection h2 {
  color: #fff;
}

.dark-mode .document-selection {
  background-color: #2c2c2c;
}

.document-select,
.difficulty-select {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.dark-mode .document-select,
.dark-mode .difficulty-select {
  background-color: #1a1a1a;
  border-color: #404040;
  color: #fff;
}

.document-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Center loading spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

/* Improved results display */
.success-message,
.score-message {
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 600px;
  animation: fadeIn 0.5s ease-in;
}

.success-message {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.dark-mode .success-message {
  background-color: #1e4a2e;
  border-color: #2a5a3c;
  color: #ffffff;
}

.score-message {
  background-color: #e3f2fd;
  border: 1px solid #bee5eb;
  color: #0c5460;
}

.dark-mode .score-message {
  background-color: #1a3f4d;
  border-color: #265d6d;
  color: #ffffff;
}

.score-message h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.score-details {
  font-size: 1.1rem;
  margin-top: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Certificate styles */
.certificate-container {
  margin: 2rem auto;
  max-width: 800px;
}

.certificate-preview {
  margin: 1.5rem 0;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.certificate {
  background-color: #fff;
  color: #333;
  padding: 2rem;
  position: relative;
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(45deg, rgba(0, 123, 255, 0.05) 25%, transparent 25%, transparent 50%, rgba(0, 123, 255, 0.05) 50%, rgba(0, 123, 255, 0.05) 75%, transparent 75%, transparent);
  background-size: 20px 20px;
}

.dark-mode .certificate {
  background-color: #2c2c2c;
  color: #fff;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.05) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.05) 75%, transparent 75%, transparent);
}

.certificate-header {
  text-align: center;
  border-bottom: 2px solid #007bff;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certificate-logo {
  margin-top: 1rem;
}

.logo-placeholder {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}

.certificate-content {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.certificate-text {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.certificate-name {
  font-size: 2rem;
  margin: 1rem 0;
  color: #007bff;
  font-family: 'Brush Script MT', cursive;
}

.dark-mode .certificate-name {
  color: #4da3ff;
}

.certificate-document {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-style: italic;
}

.certificate-difficulty {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  color: #666;
}

.dark-mode .certificate-difficulty {
  color: #aaa;
}

.certificate-score {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.certificate-score span {
  font-weight: bold;
  color: #007bff;
}

.dark-mode .certificate-score span {
  color: #4da3ff;
}

.certificate-date {
  font-size: 1rem;
  margin-top: 1.5rem;
  font-style: italic;
}

.certificate-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.certificate-signature {
  text-align: center;
  width: 200px;
}

.signature-line {
  border-top: 1px solid #333;
  margin-bottom: 0.5rem;
}

.dark-mode .signature-line {
  border-color: #fff;
}

.certificate-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.view-certificate-button,
.download-certificate-button,
.generate-certificate-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.view-certificate-button {
  background-color: #6c757d;
  color: white;
  border: none;
}

.view-certificate-button:hover {
  background-color: #5a6268;
}

.download-certificate-button {
  background-color: #28a745;
  color: white;
  border: none;
}

.download-certificate-button:hover {
  background-color: #218838;
}

.generate-certificate-button {
  background-color: #007bff;
  color: white;
  border: none;
}

.generate-certificate-button:hover {
  background-color: #0069d9;
}

.dark-mode .view-certificate-button {
  background-color: #495057;
}

.dark-mode .view-certificate-button:hover {
  background-color: #343a40;
}

.dark-mode .download-certificate-button {
  background-color: #218838;
}

.dark-mode .download-certificate-button:hover {
  background-color: #1e7e34;
}

.dark-mode .generate-certificate-button {
  background-color: #0069d9;
}

.dark-mode .generate-certificate-button:hover {
  background-color: #0062cc;
}

/* Add these styles to your existing MCQActivity.css file */

.cooling-period-warning {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff3cd;
  color: #856404;
  border-radius: 6px;
  border-left: 4px solid #ffc107;
}

.dark-mode .cooling-period-warning {
  background-color: #3a3000;
  color: #ffe066;
  border-left-color: #ffc107;
}

.attempt-counter {
  font-size: 0.9rem;
  margin-left: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: #e9ecef;
  color: #495057;
  border-radius: 4px;
  font-weight: normal;
}

.dark-mode .attempt-counter {
  background-color: #343a40;
  color: #adb5bd;
}

/* Add this to the document select options to show attempts */
.document-select option {
  padding: 8px;
}

/* Add these styles to your existing MCQActivity.css file */

.retry-prompt {
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.dark-mode .retry-prompt {
  background-color: #2c2c2c;
}

.retry-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.retry-button, 
.cancel-retry-button {
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.retry-button {
  background-color: #007bff;
  color: white;
}

.retry-button:hover {
  background-color: #0069d9;
}

.cancel-retry-button {
  background-color: #6c757d;
  color: white;
}

.cancel-retry-button:hover {
  background-color: #5a6268;
}

.dark-mode .retry-button {
  background-color: #0069d9;
}

.dark-mode .retry-button:hover {
  background-color: #0062cc;
}

.dark-mode .cancel-retry-button {
  background-color: #495057;
}

.dark-mode .cancel-retry-button:hover {
  background-color: #343a40;
}

/* Pass/Fail styling */
.score-message.pass {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.score-message.fail {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.dark-mode .score-message.pass {
  background-color: #1e4a2e;
  border-color: #2a5a3c;
  color: #ffffff;
}

.dark-mode .score-message.fail {
  background-color: #4a1e1e;
  border-color: #5a2a2a;
  color: #ffffff;
}

.pass-fail-status {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.no-certificate-message {
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  max-width: 600px;
}

.dark-mode .no-certificate-message {
  background-color: #2c2c2c;
} 